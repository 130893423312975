import { createRouter, createWebHistory } from "vue-router";
import i18n from "./../i18n";
import * as _ from 'lodash';

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

const routes = [
  {
    path: "/",
    redirect: i18n.locale || "en",
  },
  {
    path: "/:locale",
    component: load("Root"),
    beforeEnter: (to, from, next) => {
      if (!i18n.global.availableLocales.includes(to.params.locale)) {
        let url = to.fullPath.replace(to.params.locale, "en");
        return next(url);
      } else {
        if(!_.isNil(i18n.global.locale) && i18n.global.locale !== to.params.locale){
          let url = to.fullPath.replace(to.params.locale, i18n.global.locale);
          return next(url);
        }else{
          return next();
        }
      }
    },
    children: [
      {
        path: ":video?",
        name: "Home",
        component: load("Home"),
      },
      {
        path: "about_armor",
        name: "About Armor",
        component: load("AboutArmor"),
      },
      {
        path: "demidov_armor",
        name: "Demidov Armor",
        component: load("DemidovArmor"),
      },
      {
        path: "products",
        name: "Products",
        component: load("Products"),
      },
      {
        path: "order",
        name: "Order",
        component: load("Order"),
      },
      {
        path: "contacts",
        name: "Contacts",
        component: load("Contacts"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (isSamePage(from.fullPath, to.fullPath)) return savedPosition;
    return { top: 0 };
  },
});

function isSamePage(from, to) {
  let toLocArray = to.split("/");
  let fromLocArray = from.split("/");
  let toLoc = toLocArray.at(-1);
  let fromLoc = fromLocArray.at(-1);
  if (
    i18n.global.availableLocales.includes(toLoc) &&
    (i18n.global.availableLocales.includes(fromLoc) || fromLoc === "")
  ) {
    return true;
  }
  if (toLoc === fromLoc) return true;
  return false;
}

export default router;
