import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faTwitter,
  faLinkedin,
  faReddit,
  faTumblr,
  faQuora,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Ripple from "./directives/ripple/ripple";
import ScrollAnimate from "./directives/scrollanimate/scrollanimate";
import i18n from "./i18n";

library.add(
  faInstagram,
  faFacebook,
  faYoutube,
  faTwitter,
  faCheck,
  faTimes,
  faLinkedin,
  faReddit,
  faTumblr,
  faQuora,
  faPinterest
);

createApp(App)
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon)
  .directive("ripple", Ripple)
  .directive("scrollanimate", ScrollAnimate)
  .use(store)
  .use(router)
  .mount("#app");
