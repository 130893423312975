import { createStore } from "vuex";
import axios from "axios";
import querystring from "querystring";

export default createStore({
  state: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    sendMail({ dispatch }, data) {
      axios
        .post("https://demidovarmor.com/mail.php", querystring.stringify(data))
        .then(() => {});
    },
  },
  modules: {},
});
