export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])},
    "aboutArmor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про броню"])},
    "demidovArmor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформити замовлення"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])}
  },
  "home": {
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR - КРАЩИЙ СПОСІБ ЗАХИСТУ В ЦЬОМУ СВІТІ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми живемо у недосконалому світі. Багато людей схильні до різних ризиків. Десь явно, а десь приховано є загроза життю, і не завжди це пов'язано з професійною діяльністю. Існують різні засоби індивідуального захисту, і щоб вибрати для себе ідеальний продукт, ми розкриємо всі нюанси представленої у світі броні."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost - ВАША ПРИХОВАНА ВДОСКОНАЛЕННЯ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demidov Armor - компанія, яка докорінно змінила підхід до Вашої особистої безпеки. Пройшовши шлях за 10 років розробок у галузі балістики, композитних матеріалів та біонічного дизайну, було отримано унікальний анатомічний захист, здатний врятувати життя та здоров'я. Команді Demidov Armor вдалося створити виріб, який не тільки надійно захищає, залишаючись невидимою, але й переводить сферу балістичного захисту на новий рівень, створивши унікальний ArGhost."])}
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт DEMIDOV ARMOR"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Броня"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торс"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для Жінок"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чохол"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумка"])}
    },
    "section4": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задати питання"])}
    },
    "linkButtons": {
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дивитися більше"])},
      "b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])}
    }
  },
  "aboutArmor": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найкращі у світі приховані обладунки"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Унікальна запатентована технологія дозволяє створювати броню, яка не має аналогів у світі та багаторазово перевершує за своїми характеристиками ту, що представлена на світовому ринку."])}
    },
    "section1": {
      "p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Існують армійські, поліцейські та приховані бронежилети. Ми говоритимемо лише про "])},
      "p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["приховані бронежилети."])},
      "p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раніше в бронежилетах використовувався кевлар, але тепер замість кевлару використовують високомолекулярний поліетилен, який на 40% кращий за кевлар і не боїться вологи. Практично всі представлені на світовому ринку бронежилети прихованого носіння створені за таким же принципом."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прихований бронежилет захищає від куль з пістолета та уламків. У світі існує три основні стандарти захисту:"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо вам кажуть, що ці стандарти відповідають один одному, це брехня, тому що кожен з них використовує не тільки різну зброю, але й різні вимоги до броні."])},
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Броня"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ступінь захисту"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зброя"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тупа травма"])}
      ],
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Американський (NIJ 0101.04)"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Європейський (CEN EN 1063)"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Російський (ГОСТ Р 50744-95)"])}
      ]
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо 44Magnum - потужний пістолет, куля якого має експансивну (зупиняючу) дію і для нього достатньо 4-6 мм броні, то куля з пістолета ТТ має пробиваючу властивість і броня вимагає не менше 8 мм. Також європейські стандарти відрізняються від американських за величиною тупої травми (зміщення задньої стінки броні у бік тіла під час потрапляння кулі). Вимірюється так: зброя кладеться на спеціальний пластилін і вистрілюється, а потім вимірюється вм'ятина в пластиліні."])}
    },
    "section4": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За статистикою, якщо величина тупої травми становить 44 мм, то 10% випадків вона закінчується летальним результатом. На жаль, немає статистики, на якому рівні усунення, скільки зламаних ребер, внутрішньої кровотечі, коми. І немає статистики, скільки людей при попаданні кулі в броню змогли швидко і адекватно відреагувати на напад, оскільки 90% броні покриває ребра."])}
    },
    "section5": {
      "p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практично всі бронежилети "])},
      "p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прихованого носіння"])},
      "p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" створені за одним і тим же принципом:"])},
      "p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["щоб броня була "])},
      "p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРИХОВАНА"])},
      "p2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", вона повинна лежати на тілі."])},
      "p3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["щоб  "])},
      "p3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛЕЖАЛА "])},
      "p3-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на тілі, вона повинна бути м'якою."])}
    },
    "section6": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У ВСІХ БРОЖИЛЕТІВ НА РИНКУ Є НЕДОЛІКИ"])},
      "minusesItems": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі шари (від 20 до 60 шарів броні) мігрують між собою, і неможливо контролювати зріз броні, і товщина 6-10 мм виступає з-під одягу."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Матеріал одночасно згинається тільки у двох площинах, і йому неможливо надати форму (немає геометрії тіла) і, отже, зменшується площа покриття тіла."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завдяки м'якості виробу при ударі виникає велика тупа травма. А це веде до втрати не тільки здоров’я, а й життя."])}
      ],
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["І якщо обладунки так погано сидять на досить струнких людях, то що робити тим, у кого надмірна вага або спортивне тіло, обладунки для жінок взагалі хвора тема."])}
    }
  },
  "armor": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми раді представити вам продукт нашої компанії."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost - куленепробивний жилет прихованого носіння нового покоління"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Унікальна запатентована технологія дозволяє створювати броню, яка не має аналогів у світі та багаторазово перевершує за своїми характеристиками ту, що представлена на світовому ринку."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До жилета прихованого носіння пред'являються кілька важливих вимог:"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРИХОВАНІСТЬ"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Захист не помітний у будь-якому одягу."])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Комфортне носіння та захисна зона."])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БАЛІСТИКА"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Максимальний захист від зброї."])},
      "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Мінімальна величина запобіжної травми при попаданні кулі."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR змінив прийняту концепцію виготовлення обладунків."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обладнання створюється індивідуально і має масу переваг. Для цього:"])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіло сканується і прес форми створюються з точною геометрією тіла."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливість зафіксувати зріз броні панелі і цим звузити його."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливе надання кожному шару точної форми людського тіла (наявність живота, лопаток, грудей, талії), що дає максимальну площу захисту тіла."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оскільки всі шари пов'язані між собою, створюється додаткова жорсткість, що значно знижує рівень тупої травми в рази і не пробивається колючими предметами."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оскільки виріб виконаний відповідно до форми тіла, вага виробу рівномірно розподіляється по тілу, що робить броню зручнішою для носіння."])}
      ]
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БАЛІСТИКА"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порівняльні характеристики стрільби штатних бронежилетів прихованого носіння IIIANIJ(USA) та бронежилетів DEMIDOV ARMOR"])},
      "guns": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44Magnum"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маса кулі,г 15,5"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова швидкість, м/с 452"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Енергія кулі, Дж 1570"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пістолет ТТ, 7,62*25"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маса кулі,г 5,5"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова швидкість, м/с 420-500"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Енергія кулі, Дж J 480-700"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glok17, 9*19"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маса кулі,г 7,45"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова швидкість, м/с 375"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Енергія кулі, Дж 480-617"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дробовик"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масса патрона,г 29"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова швидкість, м/с 450"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Енергія кулі, Дж 2900"])}
          ]
        }
      ]
    },
    "section4": {
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартний захист США (NIJ 0101.04), мм"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR, мм"])}
      ]
    },
    "section5": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можна з упевненістю сказати, що наша броня ArGhost - найкращий прихований захист:"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost дійсно прихований і не просто так називається"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідеально облягає тіло та зручний у носінні"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Має кращі балістичні характеристики"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost від DEMIDOV ARMOR"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["єдина броня у світі, яка 365 днів на рік захищає ваше життя у будь-яку погоду та у будь-якому одязі."])}
    }
  },
  "products": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected Threat - Neutralized Threat"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected Protection - Neutralized Protection"])}
    },
    "anchors": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для Жінок"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чохол"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумка"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торс"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])}
    ],
    "section6": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LUXURY"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При створенні бронепанелей ми використовуємо найкращі балістичні матеріали у світі. І також приділяємо особливу увагу зовнішньому вигляду панелей. Для броні, яку планується носити часто, ми використовуємо alcantara та краї обшиті зносостійкою стрічкою."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для особливих клієнтів, які цінують розкіш і бажають отримати справжній витвір балістичного мистецтва, ми пропонуємо використовувати в обробці натуральну шкіру, навіть екзотичну. Ця броня може функцію - носіння без чохла. Пропонується вона тільки в пакеті Platinum та Gold"])}
    },
    "section7": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗАХИСТ ДЛЯ ЖІНОК"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не секрет, що наші жінки повністю позбавлені прихованого носіння броні, і причина цього не лише у складності обробки балістичних матеріалів, а й у тому, що жіноча фігура унікальніша та індивідуальніша. Адже крім того, що броня повинна бути зручною і мати необхідні балістичні властивості, в першу чергу вона повинна бути прихована, максимально повторюючи геометрію жінки. Унікальна запатентована технологія виготовлення дозволяє нашій компанії DEMIDOV ARMOR створювати захист для жінок будь-якої фігури та з будь-яким розміром грудей."])}
    },
    "section8": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧОХОЛ ДЛЯ БРОНІ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основним матеріалом у світі для носіння бронежилетів є плащівки, але оскільки цей матеріал непрактичний для «бронежилетів прихованого носіння», зараз набирає популярності виготовлення з трикотажних тканин. Чохол для броні з трикотажу натягується на тіло, роблячи бронежилет більш прихованим (особливо там, де немає бічного захисту)."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, у таких матеріалів крім переваг є і недоліки:"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда DEMIDOV ARMOR під час розробки носія поставила перед ним основні пріоритети:"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальний комфорт. Ми використовуємо найкращі матеріали для вентиляції та відведення вологи з внутрішньої сторони чохла."])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна скритність. Ми виготовляємо їх для кожного комплекту броні. Для верхньої частини носіння ми використовуємо штучну замшу, що робить носій приємним на дотик, а також завдяки цьому матеріалу чохол зношується довше, матеріал не блищить і краще прилягає."])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При замовленні нашого товару в комплект входить щонайменше 4 змінні носії різних кольорів на вибір (чорний, бежевий, білий)."])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін служби броніпакета - 5 років, але через ніжність трикотажного полотна такі чохли швидко рвуться"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трикотажний чохол при носінні швидко розтягується і втрачає форму, а можливість регулювання натягу зазвичай відсутня"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми помітили, що при неоновому освітленні (нічні клуби тощо) трикотажні носії можуть просвічуватись з-під одягу, виявляючи наявність броні"])}
      ]
    },
    "section9": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СУМКА ДЛЯ ПЕРЕНОСУ БРОНІ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR розробила унікальну сумку для носіння броні:"])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шкіряна сумка, зшита вручну у вигляді рюкзака, дозволяє носити її, не привертаючи зайвої уваги, навіть коли ви в класичному костюмі"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["розміри сумки не більше, ніж необхідно для ручної поклажі для перевезення в літаку"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чотири знімних чохла вільно поміщаються в сумці"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["є можливість швидко дістати зброю"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на задній стінці встановлені додаткові приховані бронепанелі (IIIANIJ), що дозволяють створити броньовий щит розміром 30см*90см."])}
      ]
    },
    "section10": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОРС ДЛЯ БРОНІ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оскільки броня DEMIDOV ARMOR виготовляється індивідуально з максимальним повторенням геометрії тіла клієнта, для кращого збереження цієї форми ми рекомендуємо зберігати її не в сумці, а помістити на торсі, створеному спеціально для вас. Крім того, цей виріб чудово прикрасить ваш гардероб."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропонуємо створити цей виріб із композитів та сталі."])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компанія DEMIDOV ARMOR представляє вам послугу пошиття чоловічого одягу класу люкс для прихованого носіння ArGhost та зброї."])},
      "p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Понад 25 років ми шиємо класичний чоловічий одяг "])},
      "p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BESPOKE."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Наша спеціалізація - пошиття класичного одягу на складну фігуру."])},
      "p4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Величезний досвід у мистецтві пошиття одягу, коли необхідно приховати особливості фігури (зайві кілограми, сколіоз тощо) залучали VIP-персон із проханням бронювати їхній одяг. Ні для кого не секрет, що існує безліч місць і ситуацій, коли людина дуже обмежена в особистому захисті та очевидна присутність обладунків на ньому недоречна. Звичайно, оскільки будь-яка броня має свою товщину, дуже важливо пошити одяг, який виглядатиме природно, не звертаючи уваги на те, що під ним знаходиться броня і навіть зброя. Одяг повинен максимально приховувати присутність під нею ArGhost, нічого не повинно привертати до себе надто багато уваги."])},
      "p4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Ваша перевага в зброї та обладунках."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR SUITS"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одяг шиють найкращі кравці Європи з дотриманням усіх класичних технологій bespoke пошиття на замовлення. 80% ручної роботи гарантує надійність та комфорт при носінні."])}
    }
  },
  "order": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформити замовлення"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["безпека твого життя"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модель STRONG"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індивідуальні продукти:"])},
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Броня"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумка"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінні чохли"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торс"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Костюми + сорочки"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціна"])}
      ],
      "price_by_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за запитом"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Оскільки цей товар індивідуальний та виготовляється за індивідуальним замовленням, термін виготовлення від 6 місяців після передоплати."])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб отримати цей товар до 3 місяців, потрібна повна оплата у подвійному розмірі."])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["**Наша компанія не надає послуги особам, які перебувають під санкціями США або пов'язані з терористичною діяльністю. Маємо право відмовити у виробництві без пояснення причин."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовте консультацію"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі продукти створені спеціально для забезпечення максимального комфорту, високих балістичних характеристик та прихованої переваги."])},
      "i1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я*"])},
      "i2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище*"])},
      "i3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна"])},
      "i4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "i5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошта*"])},
      "i6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть пакет"])},
      "i7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет"])},
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати"])},
      "packages": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])}
      ]
    }
  },
  "contacts": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the safety for your life"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офіс представництва DEMIDOV ARMOR у Европі"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старотроїтська, 42, Бровари, Україна, Київ, 07400"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офіс представництва DEMIDOV ARMOR у США"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texas Dallas"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
    },
    "form": {
      "i1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я*"])},
      "i2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище*"])},
      "i3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна"])},
      "i4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "i5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошта*"])},
      "i6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення"])},
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати"])}
    }
  },
  "messages": {
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це поле не може бути порожнім"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заповніть будь-ласка всі обов'язкові поля форми"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення надіслано"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивитися Відео"])}
  }
}