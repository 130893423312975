export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "aboutArmor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О броне"])},
    "demidovArmor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформить заказ"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])}
  },
  "home": {
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR - ЛУЧШИЙ СПОСОБ ЗАЩИТЫ В ЭТОМ МИРЕ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы живем  в несовершенном мире.  Многие люди подвержены различным рискам. Где-то явно, а где-то скрыто присутствует угроза жизни, и не всегда это связано с профессиональной деятельностью. Существуют различные средства индивидуальной защиты, и чтоб выбрать для себя идеальный продукт,  мы раскроем все нюансы представленной в мире брони."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost - ВАШЕ СКРЫТОЕ СОВЕРШЕНСТВО"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demidov Armor - компания,  которая коренным образом изменила подход  к  ВАШЕЙ  личной безопасности. Пройдя путь за 10 лет разработок в области баллистики, композитных материалов и бионического дизайна, была получена уникальная анатомическая защита, способная спасти жизнь и здоровье. Команде Demidov Armor удалось создать изделие, которое не только надежно защищает, оставаясь невидимой, но и переводит сферу баллистической защиты совершенно на новый уровень, создав уникальный ArGhost."])}
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукты DEMIDOV ARMOR"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Броня"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торс"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для Женщин"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чехол"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумка"])}
    },
    "section4": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать вопрос"])}
    },
    "linkButtons": {
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть больше"])},
      "b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])}
    }
  },
  "aboutArmor": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лучшие в мире скрытые доспехи"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальная запатентованная технология позволяет создавать броню, не имеющую аналогов в мире и многократно превосходящую по своим характеристикам ту, что представлена на мировом рынке."])}
    },
    "section1": {
      "p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Существуют армейские, полицейские и скрытые бронежилеты. Мы будем говорить только о "])},
      "p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скрытых бронежилетах."])},
      "p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раньше в бронежилетах использовался кевлар, но теперь вместо кевлара используют высокомолекулярный полиэтилен, который на 40% лучше  кевлара и не боится влаги. Практически все представленные на мировом рынке бронежилеты скрытого ношения созданы по такому же принципу."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрытый бронежилет защищает от пуль из пистолета и осколков. В мире существует три основных стандарта защиты:"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вам говорят, что эти стандарты соответствуют друг другу, то это ложь, так как каждый из них использует не только разное оружие, но и разные требования к броне."])},
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Броня"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степень защиты"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оружие"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запреградная травма"])}
      ],
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Американский (NIJ 0101.04)"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европейский (CEN EN 1063)"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Росийский (ГОСТ Р 50744-95)"])}
      ]
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если 44Magnum - мощный пистолет, пуля которого обладает экспансивным (останавливающим )  действием  и для него достаточно 4-6 мм брони, то пуля из пистолета ТТ имеет пробивающее свойство и броня требует не менее 8 мм. Также европейские стандарты отличаются от американских по величине тупой травмы (смещение задней стенки брони в сторону тела при попадании пули). Измеряется так: доспех кладется на специальный пластилин и выстреливается, а затем измеряется вмятина в пластилине."])}
    },
    "section4": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По статистике, если величина тупой травмы составляет 44 мм, то в 10% случаев она заканчивается летальным исходом. К сожалению, нет статистики, на каком уровне смещения, сколько сломанных ребер, внутреннего кровотечения, комы. И нет статистики  сколько людей при попадании пули в броню смогли быстро и адекватно отреагировать на нападение, так как  90%  брони покрывает ребра."])}
    },
    "section5": {
      "p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практически все бронежилеты "])},
      "p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скрытого ношения"])},
      "p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" созданы по одному и тому же принципу:"])},
      "p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы  броня была "])},
      "p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СКРЫТОЙ"])},
      "p2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", она должна лежать на теле."])},
      "p3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы "])},
      "p3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛЕЖАЛА "])},
      "p3-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на теле, она должна быть мягкой."])}
    },
    "section6": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МИНУСЫ ВСЕХ БРОНИРОВАННЫХ ЖИЛЕТОВ, ПРЕДСТАВЛЕННЫХ  НА МИРОВОМ РЫНКЕ"])},
      "minusesItems": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все слои от 20 до 60 слоев брони мигрируют между собой и невозможно контролировать срез брони, и толщина 6-10 мм выступает из-под одежды."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материал одновременно изгибается только в двух плоскостях и ему невозможно придать форму (нет геометрии тела) и, следовательно, уменьшается  площадь покрытия тела."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из-за мягкости брони при ударе возникает сильная запреградная травма. Что приводит не только к потере здоровья, но и жизни."])}
      ],
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["И если доспехи так плохо сидят на довольно стройных людях, то что же делать тем, у кого избыточный вес или спортивное тело, доспехи для женщин вообще больная тема."])}
    }
  },
  "armor": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы рады представить вам продукт нашей компании."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost - Пуленепробиваемый жилет скрытого ношения нового поколения"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальная запатентованная технология позволяет создавать броню, не имеющую аналогов в мире и многократно превосходящую по своим характеристикам ту, что представлена на мировом рынке."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К жилету скрытого ношения предъявляются несколько важных требований:"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СКРЫТОСТЬ"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Защита не видна под любой одеждой."])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Комфортное ношение и защитная зона."])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БАЛЛИСТИКА"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Максимальная защита от оружия."])},
      "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Минимальная величина запреградной травмы при попадании пули."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR изменил принятую концепцию изготовления доспехов."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доспех создается индивидуально и имеет массу преимуществ. Для этого:"])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тело сканируется, и пресс формы создаются с точной геометрией тела."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможность зафиксировать срез брони панели и тем самым сузить его."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможно придание каждому слою точной формы человеческого тела (наличие живота, лопаток, груди, талии), что дает максимальную площадь защиты тела."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскольку все слои связаны между собой, создается дополнительная жесткость, значительно снижающая уровень тупой травмы в разы и не пробивающаяся колющими предметами."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскольку изделие выполнено в соответствии с формой тела, вес изделия равномерно распределяется по телу, что делает броню более удобной для ношения."])}
      ]
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БАЛЛИСТИКА"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сравнительные характеристики стрельбы штатных бронежилетов скрытого ношения IIIA NIJ(USA) и бронежилетов DEMIDOV ARMOR"])},
      "guns": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44Magnum"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масса патрона,г 15,5"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная скорость, м/с 452"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Энергия патрона, Дж 1570"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пистолет ТТ, 7,62*25"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масса патрона,г 5,5"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная скорость, м/с 420-500"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Энергия патрона, Дж J 480-700"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glok17, 9*19"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масса патрона,г 7,45"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная скорость, м/с 375"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Энергия патрона, Дж 480-617"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дробовик"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масса патрона,г 29"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная скорость, м/с 450"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Энергия патрона, Дж 2900"])}
          ]
        }
      ]
    },
    "section4": {
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартная защита США (NIJ 0101.04), мм"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR, мм"])}
      ]
    },
    "section5": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно с уверенностью сказать, что наша броня ArGhost – лучшая скрытая защита:"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost действительно скрыт и не просто так называется"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идеально облегает тело и удобен в носке"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обладает лучшими баллистическими характеристиками"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost от DEMIDOV ARMOR"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["единственная броня в мире, которая 365 дней в году защищает вашу жизнь в любую погоду и в любой одежде."])}
    }
  },
  "products": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected Threat - Neutralized Threat"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected Protection - Neutralized Protection"])}
    },
    "anchors": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для Женщин"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чехол"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумка"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торс"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])}
    ],
    "section6": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LUXURY"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При создании бронепанелей мы используем лучшие баллистические материалы в мире. И так же уделяем особое внимание внешнему виду панелей. Для  брони ,которую планируется носить часто, мы используем alcantara и края обшитые износостойкой лентой."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для особых клиентов, ценящих роскошь и желающих получить настоящее произведение баллистического искусства, мы предлагаем использовать в отделке натуральную кожу, даже экзотическую. Данная броня имеет возможность функцию - ношение без чехла. Предлагается она только в пакете Platinum и Gold"])}
    },
    "section7": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗАЩИТА ДЛЯ ЖЕНЩИН"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не секрет, что наши женщины полностью лишены скрытого ношения брони, и причина этого не только в сложности обработки баллистических материалов, но и в том, что женская фигура более уникальна и индивидуальна. Ведь помимо того, что броня должна быть удобной и обладать необходимыми баллистическими свойствами, в первую очередь она должна быть скрыта, максимально повторяя геометрию женщины. Уникальная запатентованная технология изготовления позволяет нашей компании DEMIDOV ARMOR создавать защиту для женщин любой фигуры и с любым размером груди."])}
    },
    "section8": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧЕХОЛ ДЛЯ БРОНИ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основным материалом в мире для ношения бронежилетов являются плащевки, но, поскольку этот материал непрактичен для «бронежилетов скрытого ношения», сейчас набирает популярность изготовление из трикотажных тканей. Чехол для брони из трикотажа натягивается на тело, делая бронежилет более скрытым (особенно там, где нет боковой защиты)."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, у таких материалов помимо достоинств есть и недостатки:"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда DEMIDOV ARMOR при разработке носителя поставила перед ним основные приоритеты:"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальный комфорт. Мы используем лучшие материалы для вентиляции и отвода влаги с внутренней стороны чехла."])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная скрытность. Мы изготавливаем их индивидуально для каждого комплекта брони. Для верхней части ношения мы используем искусственную замшу, что делает носитель приятным на ощупь, а также благодаря этому материалу чехол изнашивается дольше, материал не блестит и лучше прилегает."])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При заказе нашего товара в комплект входит не менее 4 сменных носителей разных цветов на выбор (черный, бежевый, белый)."])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок службы бронипакета - 5 лет, но  из-за нежности трикотажного полотна такие чехлы быстро рвутся"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трикотажный чехол при носке быстро растягивается и теряет форму, а возможность регулировки натяжения обычно отсутствует"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы заметили, что при неоновом освещении (ночные клубы и т. д.) трикотажные носители могут просвечивать из-под одежды, тем самым обнаруживая наличие брони."])}
      ]
    },
    "section9": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СУМКА ДЛЯ ПЕРЕНОСА БРОНИ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR разработала уникальную сумку для ношения брони:"])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кожаная сумка, сшитая вручную в виде рюкзака, позволяет носить ее, не привлекая излишнего внимания, даже когда вы в классическом костюме"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["размеры сумки не больше, чем необходимо для ручной клади для перевозки в самолете"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["четыре съемных чехла свободно помещаются в сумке"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["есть возможность  быстро достать оружие"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на задней стенке установлены дополнительные скрытые бронепанели (IIIA NIJ), позволяющие создать броневой щит размером 30см * 90см."])}
      ]
    },
    "section10": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОРС ДЛЯ БРОНИ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскольку броня DEMIDOV ARMOR изготавливается индивидуально с максимальным повторением геометрии тела клиента, для лучшего сохранения этой формы мы рекомендуем хранить ее не в сумке, а поместить на торсе, созданном специально для вас. Кроме того, это изделие отлично украсит ваш гардероб."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предлагаем создать это изделие из композитов и стали."])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания DEMIDOV ARMOR представляет вам услугу пошива мужской одежды класса люкс для скрытого ношения ArGhost и оружия."])},
      "p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Более 25 лет мы шьем классическую мужскую одежду "])},
      "p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BESPOKE."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Наша специализация - пошив  классической одежды на сложную фигуру."])},
      "p4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Огромный опыт в искусстве пошива одежды, когда необходимо скрыть особенности фигуры (лишние килограммы, сколиоз и т. д.) привлекали VIP-персон с просьбой бронировать их одежду. Ни для кого не секрет, что существует множество мест и ситуаций, когда человек очень ограничен в личной защите и очевидное присутствие доспехов на нем неуместно. Естественно, поскольку любая броня имеет свою толщину, очень важно сшить одежду, которая будет выглядеть естественно, не обращая внимания на то, что под ней находится броня и даже оружие. Одежда должна максимально скрывать присутствие под ней ArGhost, ничего не должно привлекать к себе слишком много внимания."])},
      "p4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* ваше превосходство в оружии и доспехах."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR SUITS"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одежду шьют лучшие портные Европы с соблюдением всех классических технологий bespoke пошива на заказ. 80% ручной работы гарантирует надежность и комфорт при ношении."])}
    }
  },
  "order": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформить заказ"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["безопасность для твоей жизни"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модель STRONG"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные продукты:"])},
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Броня"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумка"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменные чехлы"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торс"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Костюмы + рубашки"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])}
      ],
      "price_by_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по запросу"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Поскольку данный товар индивидуален и изготавливается по индивидуальному заказу, срок изготовления от 6 месяцев после предоплаты."])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы получить этот товар до 3 месяцев, требуется полная оплата в двойном размере."])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["**Наша компания не предоставляет услуги лицам, находящимся под санкциями США или связанным с террористической деятельностью. Имеем право отказать в производстве без объяснения причин."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закажите консультацию"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все продукты созданы специально для обеспечения максимального комфорта, высочайших  баллистических характеристик и скрытого превосходства."])},
      "i1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя*"])},
      "i2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия*"])},
      "i3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
      "i4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "i5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта*"])},
      "i6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пакет"])},
      "i7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет"])},
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отослать"])},
      "packages": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])}
      ]
    }
  },
  "contacts": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the safety for your life"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офис представительства DEMIDOV ARMOR в Европе"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старотроитская, 42, Бровары, Укриана, Киев, 07400"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офис представительства DEMIDOV ARMOR в США"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texas Dallas"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
    },
    "form": {
      "i1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя*"])},
      "i2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия*"])},
      "i3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
      "i4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "i5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта*"])},
      "i6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отослать"])}
    }
  },
  "messages": {
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["это поле не может быть пустым"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все обязательные поля формы"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение успешно отослано"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть Видео"])}
  }
}