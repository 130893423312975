let animatedScrollObserver;

let ScrollAnimate = {
  created(el, binding) {
    animatedScrollObserver = new IntersectionObserver(
      (entries, animatedScrollObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(`enter-${binding.value}`);
            animatedScrollObserver.unobserve(entry.target);
          }
        });
      }
    );
  },
  beforeMount: function (el, binding) {
    el.classList.add(`before-${binding.value}`);
    animatedScrollObserver.observe(el);
  },
};

export default ScrollAnimate;
