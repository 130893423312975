export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "aboutArmor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Armor"])},
    "demidovArmor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
  },
  "home": {
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR THE BEST WAY TO PROTECT IN THIS WORLD"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We live in an imperfect world. Many people are at risk. There are various personal protective equipment and in order to choose the perfect product for ourselves, we will reveal all the nuances of the armor presented in the world."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost - YOUR HIDDEN EXCELLENCE"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demidov Armor is a company that has fundamentally changed the approach to YOUR personal safety. Having passed the way for 10 years of development in the field of ballistics, composite materials and bionic design, a unique anatomical protection was obtained that can save lives and health. The Demidov Armor team managed to create a product that not only reliably protects while remaining invisible, but also take the sphere of ballistic protection to a completely new level by creating a unique ArGhost."])}
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR PRODUCTS"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armor"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torso"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Women"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrying"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bag"])}
    },
    "section4": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask a question"])}
    },
    "linkButtons": {
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look more"])},
      "b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])}
    }
  },
  "aboutArmor": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The world's best concealed body armor"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The unique patented technology allows us to create armor that has no analogues in the world and is many times superior in its characteristics to what is presented on the world market."])}
    },
    "section1": {
      "p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are three kinds of body armor-police, military, and concealable.We will only talk about"])},
      "p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concealable body armor."])},
      "p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previously, Kevlar was used in body armor, but now, instead of Kevlar, we use high-molecular polyethylene, which is 40% stronger than Kevlar and is waterproof. Almost all kinds of \"concealble\"  body armors offered on the world market are created according to the same principle."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concealed body armor protects against a bullet from a pistol as well as shrapnel. There are three main protection standards in the world:"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are told that these standards correspond to each other, then this is a lie, since each of them uses not only different weapons, but also different requirements for armor."])},
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armor"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level protection"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blunt trauma"])}
      ],
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American (NIJ 0101.04)"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European (CEN EN 1063)"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian (ГОСТ Р 50744-95)"])}
      ]
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If 44Magnum is a powerful pistol. a bullet of which has expansive properties (stopping effect) and 4-6 mm of armor is enough for it, then a bullet from a TT pistol has a penetrating property and armor needs at least 8mm. Also, European standards differ from American standards in the amount of blunt trauma (displacement of the rear wall of the armor towards the body when a bullet hits). It is measured like this: the armor is placed on a special clay and is shot a, and then the dent in the clay is measured."])}
    },
    "section4": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to statistics, if the magnitude of a blunt injury is 44mm, then in 10% of cases it is fatal. Unfortunately, there are no statistics showing what level of displacement, how many broken ribs sustained, internal bleeding caused, comas induced, or how many people who were hit by a bullet were able to quickly respond appropriately and protect themselves from the attacker, since 90% of the armor coverage falls on the ribs."])}
    },
    "section5": {
      "p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost all \"hidden wearing\" body armor is "])},
      "p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created according"])},
      "p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to the same principle:"])},
      "p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for the armor to be "])},
      "p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVERED"])},
      "p2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", it must lie on the body"])},
      "p3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to "])},
      "p3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIE"])},
      "p3-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" on the body, it should be soft"])}
    },
    "section6": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are Disadvantages with ALL ARMORED VESTS ON THE MARKET"])},
      "minusesItems": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armor with 20 to 60 layers of armor migrate among themselves and it is impossible to fix the cut of the armor and the thickness of 6-10 mm stands out from under the clothes."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The material bends at the same time only in two planes and it is not possible to give it a shape to match body geometry and therefore it reduces the area of coverage of the body."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to the softness of the product, there is a great blunt trauma when hit. And this leads not only to the loss of health, but also of life."])}
      ],
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additionally, if armor sits so poorly on fairly slender people, then what about those who are overweight or athletic. Armor for women is also generally a sore subject."])}
    }
  },
  "armor": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are pleased to present you a product of our Company"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost - Next Generation Bulletproof Concealed Vest"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The unique patented technology makes it possible to create armor that has no analogues in the world and is many times superior in its characteristics to the one on the world market"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are several important requirements for a hidden-carry vest:"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIDDEN"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Protection is not visible in any clothing."])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Wearing comfort and protection area."])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BALLISTICS"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● Maximum protection against weapons."])},
      "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["● The minimum level of blunt trauma when hit by a bullet."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR has changed the accepted standards for making armor."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Armor is created individually and has a lot of advantages. Our process has several steps:"])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The body is scanned and the molds are created with the exact geometry of the torso."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ability to fix the edge of the armor and reduce the thickness."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giving each layer the exact shape of the human body (the presence of the abdomen, shoulder blades, chest, waist, etc), and the maximum area of protection for the body is offered."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since all layers of armor are interconnected, additional rigidity is created, significantly reducing the level of blunt trauma at times and ensuring piercing objects do not break through the armor."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the product is made according to the shape of the body, the weight of the product is evenly distributed over the body, making the armor more comfortable to wear."])}
      ]
    },
    "section3": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BALLISTICS"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparative characteristics of shooting standard body armor of concealed wearing IIIA and armor DEMIDOV ARMOR"])},
      "guns": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44Magnum"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bullet weight,g 15,5"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzzle velocity, m/s 452"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy of a bullet, J 1570"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistol ТТ, 7,62*25"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bullet weight,g 5,5"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzzle velocity, m/s 420-500"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy of a bullet, J 480-700"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glok17, 9*19"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bullet weight,g 7,45"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzzle velocity, m/s 375"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy of a bullet, J 480-617"])}
          ]
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shotgun"])},
          "bullet": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bullet weight,g 29"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzzle velocity, m/s 450"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy of a bullet, J 2900"])}
          ]
        }
      ]
    },
    "section4": {
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard armor USA (NIJ 0101.04), mm"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR, mm"])}
      ]
    },
    "section5": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can safely say that our armor ArGhost is the best concealed body armor:"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost is really hidden and not just called that"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfectly fits the body and is comfortable to wear"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has the best ballistic performance"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ArGhost by DEMIDOV ARMOR"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is the only armor in the world that protects your life 365 days a year in any weather and in any clothing"])}
    }
  },
  "products": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected Threat - Neutralized Threat"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected Protection - Neutralized Protection"])}
    },
    "anchors": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Womens"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrying"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bag"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torso"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])}
    ],
    "section6": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LUXURY"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use the best ballistic materials in the world to create our armor panels. And we also pay special attention to the appearance of the panels. For armor that is planned to be worn frequently, we use alcantara and the edges are lined with wear-resistant tape."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For special clients who value luxury and want to get a real piece of ballistic art, we suggest using natural leather for decoration, even exotic one. This armor has the ability to carry it without a cover. It is offered only in the Platinum and Gold package."])}
    },
    "section7": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROTECTION FOR WOMEN"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is no secret that our women are completely deprived of the hidden wearing of armor, and the reason for this is not only the complexity of processing ballistic materials, but also that the female figure is more unique and individual. Indeed, in addition to the fact that the armor should be comfortable and have the necessary ballistic properties, first of all it should be hidden, repeating the geometry of the woman as much as possible. The unique patented manufacturing technology allows our company DEMIDOV ARMOR to create protection for women of any shape and with any breast size."])}
    },
    "section8": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARRYING"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The main material in the world for wearing body armor is raincoat fabric, but since this material is impractical for \"hidden body armor\", production from knitted fabrics is now gaining popularity. A jersey armor pouch stretches over the body, making the body armor more concealed (especially where there is no side protection)."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, in addition to advantages, such materials also have disadvantages:"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The DEMIDOV ARMOR team, when developing the media, set the main priorities for it:"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum comfort. We use the best materials for ventilation and moisture removal from the inside of the case."])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum stealth. We make them individually for each set of armor. For the upper part of wearing, we use artificial suede, which makes the wearer pleasant to the touch, and also thanks to this material, the cover wears out longer, the material does not shine and fits better."])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When ordering our product, the kit includes at least 4 removable media of different colors to choose from (black, beige, white)."])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service life of the armor package is 5 years, but due to the tenderness of the knitted fabric, such covers quickly break."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When worn, the knitted cover quickly stretches and loses its shape, and the ability to adjust the tension is usually absent."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've noticed that under neon lighting (nightclubs, etc.), knitwear can show through from underneath clothing, thereby revealing the presence of armor."])}
      ]
    },
    "section9": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARMOR TRANSFER BAG"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR has developed a unique bag for carrying armor:"])},
      "targetsList": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leather bag, sewn by hand in the form of a backpack, allows you to carry it without attracting undue attention, even when you are in a classic suit"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the size of the bag is not larger than necessary for hand luggage for transportation on the plane"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["four removable covers fit freely in the bag"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is an opportunity to quickly get a weapon"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additional hidden armor panels (IIIANIJ) are installed on the rear wall, allowing you to create an armor shield measuring 30cm * 90cm"])}
      ]
    },
    "section10": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TORSO FOR ARMOR"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the DEMIDOV ARMOR armor is made individually with maximum repetition of the client's body geometry, in order to better maintain this shape, we recommend that you store it not in a bag, but place it on a torso created especially for you. In addition, this product will perfectly decorate your wardrobe."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer to create this product from composites and steel."])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The DEMIDOV ARMOR company presents you a service of tailoring luxury men's clothing for concealed wearing of ArGhost and weapons."])},
      "p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- For more than 25 years we have been making classic BESPOKE men's clothing."])},
      "p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BESPOKE."])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Our specialization is tailoring classic clothes for a complex figure."])},
      "p4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vast experience in the art of tailoring, when it was necessary to hide the features of the figure (extra pounds, scoliosis, etc.) attracted VIPs with a request to book their clothes. It's no secret that there are many places and situations where a person is very limited in personal protection and the obvious presence of armor on him is inappropriate. Naturally, since any armor has its own thickness, it is very important to sew clothes that will look natural, regardless of the fact that there is armor and even weapons underneath. Clothing should hide the presence of ArGhost underneath as much as possible, nothing should attract too much attention."])},
      "p4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* your superiority in weapons and armor."])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEMIDOV ARMOR SUITS"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The clothes are sewn by the best tailors in Europe in compliance with all classic bespoke tailoring technologies. 80% handmade guarantees reliability and wearing comfort."])}
    }
  },
  "order": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the safety for your life"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model STRONG"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom products:"])},
      "tableHead": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package - set"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armor"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replaceable carriers"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torso"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suits + shirts"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
      ],
      "price_by_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by request"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Since this product is individual and made according to an individual order, the production time is from 6 months after prepayment."])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double full payment is required to receive this item within 3 months."])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["**Our company does not provide services to persons under US sanctions or terrorist activities. We have the right to refuse production without giving reasons"])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a consultation"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products are specifically designed to provide maximum comfort, superior ballistic performance and latent superiority."])},
      "i1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name*"])},
      "i2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name*"])},
      "i3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "i4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "i5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
      "i6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your package"])},
      "i7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your package"])},
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "packages": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])}
      ]
    }
  },
  "contacts": {
    "topPage": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the safety for your life"])}
    },
    "section1": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative office of DEMIDOV ARMOR in Europe"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starotroitskaya, 42, Brovary, Kyiv region, 07400"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
    },
    "section2": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative office of DEMIDOV ARMOR in the USA"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texas Dallas"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
    },
    "form": {
      "i1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name*"])},
      "i2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name*"])},
      "i3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "i4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "i5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
      "i6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    }
  },
  "messages": {
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this field can`t be empty"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all required fields in form"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent successfully"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch Video"])}
  }
}