<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
@import "./directives/scrollanimate/scrollanimate.css";
@import "./main.scss";
@import "./media.scss";
</style>
